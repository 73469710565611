<script setup lang="ts">
import type { StyleValue } from 'vue';

type Props = {
  size?: number;
  contentStyle?: StyleValue;
  src?: string;
};

const props = withDefaults(defineProps<Props>(), {
  size: 70,
  contentStyle: () => {
    return {};
  },
  src: getCdnPath('img/avatar.png'),
});

const avatarSize = computed(() => {
  return {
    width: `${props.size}px`,
    height: `${props.size}px`,
  };
});
</script>

<template>
  <div class="avatar transition-top,width,height-300" :style="props.contentStyle">
    <img
      :src="props.src"
      :style="avatarSize"
      class="cursor-pointer hover:rotate-360 rounded-50% transition-all-300 )"
      alt="avatar"
    />
  </div>
</template>

<style scoped lang="scss">
@keyframes shadow-Animation {
  0% {
    box-shadow: 0 0 1px 1px var(--colorPrimary);
  }
  50% {
    box-shadow: 0 0 3px 3px var(--colorPrimary);
  }
  100% {
    box-shadow: 0 0 1px 1px var(--colorPrimary);
  }
}

.avatar {
  text-align: center;

  img {
    animation: shadow-Animation 3s ease-in-out infinite;
  }
}
</style>
